import React from "react";

import { createRoot } from 'react-dom/client';
import { createTheme, ThemeProvider } from '@mui/material/styles';


import "animate.css";

import App from "./App";

import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";

import { Provider } from "react-redux";
import store from "./store";

const container = document.getElementById('root');
const root = createRoot(container);
const theme = createTheme({
  typography: {
    "fontFamily": `Nimbus Sans`,
   }
}); 
root.render(

    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
