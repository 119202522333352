const card2Reducer = (isOpen = false, action) => {
    switch (action.type) {
        case 'CARD2_CLOSE':
            return false;
        case 'CARD2_OPEN':
            return true;
        default:
            return isOpen;
    }

}
export default card2Reducer;