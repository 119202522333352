const card3Reducer = (isOpen = false, action) => {
    switch (action.type) {
        case 'CARD3_CLOSE':
            return false;
        case 'CARD3_OPEN':
            return true;
        default:
            return isOpen;
    }

}
export default card3Reducer;