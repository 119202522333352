const card4Reducer = (isOpen = false, action) => {
    switch (action.type) {
        case 'CARD4_CLOSE':
            return false;
        case 'CARD4_OPEN':
            return true;
        default:
            return isOpen;
    }

}
export default card4Reducer;