const card1Reducer = (isOpen = true, action) => {
    switch (action.type) {
        case 'CARD1_CLOSE':
            return false;
        case 'CARD1_OPEN':
            return true;
        default:
            return isOpen;
    }

}
export default card1Reducer;