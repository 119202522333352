import React, { lazy } from "react";
import { Routes, Route } from "react-router-dom";

import "./App.css";
import "./index.css"

import Loadable from "./Components/Loadable";
const LandingPage = Loadable(lazy(() => import('./Components/0_LandingPage/LandingPage')));
const VideoPage = Loadable(lazy(() => import('./Components/5_VideoPlayer/VideoPlayer2')));

function App() {


  return (
    
    <Routes>
      <Route path="/ccdoohmobile" element={<LandingPage />}></Route>
      <Route path="/ccdooh" element={<VideoPage />}></Route>
    </Routes>
  );
}

export default App;
